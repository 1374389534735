import revive_payload_client_4sVQNw7RlN from "/builds/gpmp/notificacoes-inteligentes/app/smart_redirector_frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/gpmp/notificacoes-inteligentes/app/smart_redirector_frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/gpmp/notificacoes-inteligentes/app/smart_redirector_frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/builds/gpmp/notificacoes-inteligentes/app/smart_redirector_frontend/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/builds/gpmp/notificacoes-inteligentes/app/smart_redirector_frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/gpmp/notificacoes-inteligentes/app/smart_redirector_frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/builds/gpmp/notificacoes-inteligentes/app/smart_redirector_frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/gpmp/notificacoes-inteligentes/app/smart_redirector_frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_WLsn00x1qh from "/builds/gpmp/notificacoes-inteligentes/app/smart_redirector_frontend/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import i18n_yfWm7jX06p from "/builds/gpmp/notificacoes-inteligentes/app/smart_redirector_frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/builds/gpmp/notificacoes-inteligentes/app/smart_redirector_frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import markdown_nljPFoZ1ix from "/builds/gpmp/notificacoes-inteligentes/app/smart_redirector_frontend/plugins/markdown.ts";
import sentry_client_shVUlIjFLk from "/builds/gpmp/notificacoes-inteligentes/app/smart_redirector_frontend/plugins/sentry.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_WLsn00x1qh,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  markdown_nljPFoZ1ix,
  sentry_client_shVUlIjFLk
]