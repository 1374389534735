import * as Sentry from '@sentry/vue';

import { NodeEnv } from '@/enums/env';
import { StatusCode as HttpStatusCode } from '~/enums/http';

function lazyLoadSentryIntegrations () {
  if (! process.client) {
    return;
  }

  Sentry.addIntegration(Sentry.replayIntegration({
    maskAllText: false,
    blockAllMedia: false
  }));
}

function getSentryIntegrations () {
  if (! process.client) {
    return [];
  }

  const router = useRouter();

  return [Sentry.browserTracingIntegration({ router })];
}

export default defineNuxtPlugin({
  name: 'sentry',
  parallel: true,
  setup (nuxtApp) {
    const vueApp = nuxtApp.vueApp;

    const {
      APP_ENV,
      SENTRY_DSN_PUBLIC,
      SENTRY_TRACES_SAMPLE_RATE,
      SENTRY_REPLAY_SAMPLE_RATE,
      SENTRY_ERROR_REPLAY_SAMPLE_RATE
    } = useVercelEnv();

    if (NodeEnv.Local !== APP_ENV) {
      Sentry.init({
        app: vueApp,
        dsn: SENTRY_DSN_PUBLIC,
        environment: APP_ENV,
        integrations: getSentryIntegrations(),
        tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE as number,
        replaysSessionSampleRate: SENTRY_REPLAY_SAMPLE_RATE as number,
        replaysOnErrorSampleRate: SENTRY_ERROR_REPLAY_SAMPLE_RATE as number,
        beforeSend (event: any, hint: any) {
          const error = hint.originalException;
          const { response: { status = '' } = {} } = error || {};

          if (status === HttpStatusCode.NotFound) {
            return;
          }

          return event;
        }
      });

      lazyLoadSentryIntegrations();
    }

    return {
      provide: {
        sentrySetContext: Sentry.setContext,
        sentrySetUser: Sentry.setUser,
        sentrySetTag: Sentry.setTag,
        sentryAddBreadcrumb: Sentry.addBreadcrumb,
        sentryCaptureException: Sentry.captureException
      }
    };
  }
});
