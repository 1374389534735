import {
  boldRule,
  breakLineRule,
  italicRule,
  monospaceRule,
  strikethroughRule,
  removeBoldRule,
  removeBreakLineRule,
  removeItalicRule,
  removeMonospaceRule,
  removeStrikethroughRule
} from '~/static/markdown-rules';

export const baseRules: Array<[RegExp, string]> = [
  boldRule,
  italicRule,
  strikethroughRule,
  monospaceRule,
  breakLineRule
];

export const markdownRemovedBaseRules: Array<[RegExp, string]> = [
  removeBoldRule,
  removeBreakLineRule,
  removeItalicRule,
  removeMonospaceRule,
  removeStrikethroughRule
];

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('markdown-rerender', {
    mounted (el, binding) {
      const rules = [...baseRules];

      let html = binding.value;

      html = html.trim();

      rules.forEach(([pattern, template]) => {
        html = html.replace(pattern, template);
      });

      el.innerHTML = html;

      return el;
    }
  });

  return {
    provide: {
      markdown: (html: string) => {
        const rules: Array<[RegExp, string]> = [...baseRules];
        html = html.trim();

        rules.forEach(([pattern, template]: [RegExp, string]) => {
          html = html.replace(pattern, template);
        });

        return html;
      },

      removeMarkdown: (html: string) => {
        const rules: Array<[RegExp, string]> = [...markdownRemovedBaseRules];
        html = html.trim();

        rules.forEach(([pattern, template]: [RegExp, string]) => {
          html = html.replace(pattern, template);
        });

        return html;
      }
    }
  };
});
