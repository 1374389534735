import {
  boldPattern,
  italicPattern,
  monospacePattern,
  strikethroughPattern,
  breakLinePattern
} from '~/static/regex-patterns';

export const boldRule: [RegExp, string] = [boldPattern, '<strong style="font-weight: 900;">$1</strong>'];
export const breakLineRule: [RegExp, string] = [breakLinePattern, '<br>'];
export const italicRule: [RegExp, string] = [italicPattern, '<em>$1</em>'];
export const monospaceRule: [RegExp, string] = [monospacePattern, '<tt>$1</tt>'];
export const strikethroughRule: [RegExp, string] = [strikethroughPattern, '<del>$1</del>'];

export const removeBoldRule: [RegExp, string] = [boldPattern, '$1'];
export const removeBreakLineRule: [RegExp, string] = [breakLinePattern, ' '];
export const removeItalicRule: [RegExp, string] = [italicPattern, '$1'];
export const removeMonospaceRule: [RegExp, string] = [monospacePattern, '$1'];
export const removeStrikethroughRule: [RegExp, string] = [strikethroughPattern, '$1'];
