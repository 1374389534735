let boldRegex = '';
let italicRegex = '';
let monospaceRegex = '';
let strikethroughRegex = '';

const commonPattern = '\\t\\f\\v \\u00a0\\u2000-\\u200a\\u2028\\u1680\\u2029\\u202f\\u205f\\u3000\\ufeff \\S';

boldRegex = `(?<=^|\\s|\\n|\\>|\\_|~)\\*([${commonPattern}]+?)\\*`;
italicRegex = `(?<=^|\\s|\\n|\\>|~)_([${commonPattern}}]+?)_(?!blank)`;
strikethroughRegex = `(?<=^|\\s|\\n|\\>)\\~([${commonPattern}]+?)\\~`;
monospaceRegex = '(?<=^|\\s|\\n|\\>)```([\\s\\S]+?)```';

const boldPattern = new RegExp(boldRegex, 'gm');
const italicPattern = new RegExp(italicRegex, 'gm');
const monospacePattern = new RegExp(monospaceRegex, 'gm');
const strikethroughPattern = new RegExp(strikethroughRegex, 'gm');
const breakLinePattern = /[\n]/g;

export {
  boldPattern,
  italicPattern,
  monospacePattern,
  strikethroughPattern,
  breakLinePattern
};
