<template>
  <div class="h-screen relative flex justify-center items-center">
    <div class="absolute top-[20%] flex justify-center items-center flex-col text-gray-800 gap-[10px]">
      <h1 class="text-[8rem] font-semibold tracking-widest text-neutral-600 leading-normal">
        {{ getErrorStatusCode }}
      </h1>

      <p
        v-for="({ text }, index) in getErrorFeedback.content"
        :key="index"
        class="text-base"
      >
        {{ text }}
      </p>

      <button
        v-if="isInternalServerError"
        class="rounded bg-transparent flex gap-1 p-1 items-center text-sm hover:bg-neutral-200"
        @click="handleReload"
      >
        <Suspense>
          <Icon
            name="tabler:refresh"
            size="20"
          />
        </Suspense>

        {{ i18n.t('home.refresh') }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { StatusCode as HttpStatusCode } from '~/enums/http';

const error = useError();
const i18n = useNuxtApp().$i18n;

const getErrorStatusCode = computed(() => error.value.statusCode);
const isInternalServerError = computed(() => error.value.statusCode === HttpStatusCode.InternalServerError);

const getErrorFeedback = computed(() => {
  if (error.value.statusCode === HttpStatusCode.NotFound) {
    return {
      content: [
        { text: i18n.t('home.errors.404.text_1') },
        { text: i18n.t('home.errors.404.text_2') }
      ]
    };
  }

  if (error.value.statusCode === HttpStatusCode.InternalServerError) {
    return {
      content: [
        { text: i18n.t('home.errors.500.text_1') },
        { text: i18n.t('home.errors.500.text_2') }
      ]
    };
  }

  return { content: [{ text: error.value.message }] };
});

const handleReload = () => window.location.reload();
</script>
