export enum StatusCode {
  NotFound = 404,
  InternalServerError = 500,
}

export enum StatusMessage {
  NotFound = 'Not found',
  InternalServerError = 'Internal server error',
}

export enum Method {
  Post = 'POST',
  Get = 'GET'
}
