export const useVercelEnv = () => {
  const config = useRuntimeConfig();

  return {
    INGESTOR_SERVICE_URL: config.public.INGESTOR_SERVICE_URL,
    INGESTOR_SERVICE_TIMEOUT: config.public.INGESTOR_SERVICE_TIMEOUT || 5000,
    APP_ENV: config.public.APP_ENV,
    WEB_WHATSAPP_SEND_URL: config.public.WEB_WHATSAPP_SEND_URL || 'https://web.whatsapp.com/send',
    API_WHATSAPP_SEND_URL: config.public.API_WHATSAPP_SEND_URL || 'https://api.whatsapp.com/send',
    DEFAULT_REDIRECT_TIMER_IN_SECONDS: config.public.DEFAULT_REDIRECT_TIMER_IN_SECONDS,
    API_BASE_URL: config.public.API_BASE_URL,
    SENTRY_DSN_PUBLIC: config.public.SENTRY_DSN_PUBLIC,
    SENTRY_TRACES_SAMPLE_RATE: parseFloat(String(config.public.SENTRY_TRACES_SAMPLE_RATE) ?? '0'),
    SENTRY_REPLAY_SAMPLE_RATE: parseFloat(String(config.public.SENTRY_REPLAY_SAMPLE_RATE) ?? '0'),
    SENTRY_ERROR_REPLAY_SAMPLE_RATE: parseFloat(String(config.public.SENTRY_ERROR_REPLAY_SAMPLE_RATE) ?? '0')
  };
};
