import { default as _91slug_93_45_91hash_930G2sK7CaFuMeta } from "/builds/gpmp/notificacoes-inteligentes/app/smart_redirector_frontend/pages/[slug]-[hash].vue?macro=true";
import { default as indexzRl32ErgL8Meta } from "/builds/gpmp/notificacoes-inteligentes/app/smart_redirector_frontend/pages/index.vue?macro=true";
import { default as indexzEYOYfJhYQMeta } from "/builds/gpmp/notificacoes-inteligentes/app/smart_redirector_frontend/pages/ping/index.vue?macro=true";
export default [
  {
    name: "slug-hash",
    path: "/:slug()-:hash()",
    meta: _91slug_93_45_91hash_930G2sK7CaFuMeta || {},
    component: () => import("/builds/gpmp/notificacoes-inteligentes/app/smart_redirector_frontend/pages/[slug]-[hash].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/gpmp/notificacoes-inteligentes/app/smart_redirector_frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "ping",
    path: "/ping",
    component: () => import("/builds/gpmp/notificacoes-inteligentes/app/smart_redirector_frontend/pages/ping/index.vue").then(m => m.default || m)
  }
]